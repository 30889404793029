<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <cronometro-iniciar-form
          v-if="dbReady"
          :id="null"
          :idparte-trabajo="routeParams.idparte_trabajo"
          :geolocalizacion="geolocalizacion"
          :has-perm-introducir-otros-tecnicos="hasViewPerm(permissions.tiempos.introducirOtrosTecnicos)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import CronometroIniciarForm from './components/CronometroIniciarForm'
import Data from './CronometroIniciarData'
import { sync, get } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { currentDateTime } from '@/utils/date'

export default {
  components: {
    CronometroIniciarForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    ...sync(
      'cronometro',
      {
        cronometroFinicio: 'finicio',
        cronometroIdparte: 'idparte',
        cronometroLatitud: 'latitud',
        cronometroLongitud: 'longitud',
        cronometroPara: 'para',
        cronometroEsDesplazamiento: 'esDesplazamiento',
      }
    ),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Iniciar el cronómetro'
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        await Data.iniciarTrabajoParte(this, this.formData, this.routeParams.idparte_trabajo)
        this.cronometroIdparte = parseInt(this.routeParams.idparte_trabajo)
        this.cronometroFinicio = currentDateTime()
        this.cronometroLatitud = this.formData.latitud
        this.cronometroLongitud = this.formData.longitud
        this.cronometroPara = this.formData.para
        this.cronometroEsDesplazamiento = this.formData.es_desplazamiento
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>
